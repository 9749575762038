import {Result} from 'antd';
import React, {lazy, Suspense} from 'react';
import {Switch, Route} from 'react-router-dom';

// const Login = lazy(() => import("../pages/Login"));
// const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
// const ResetPassword = lazy(() => import("../pages/ResetPassword"));

// const SignUp = lazy(() => import("../pages/SignUp"));
// const Invite = lazy(() => import("../pages/Invite"));

const Users = lazy(() => import('../pages/Users'));
// const AddUser = lazy(() => import("../pages/Users/AddUser"));
const EditUser = lazy(() => import('../pages/Users/EditUser'));

const Admins = lazy(() => import('../pages/Admins'));
const EditAdmin = lazy(() => import('../pages/Admins/EditAdmin'));

const Synagogues = lazy(() => import('../pages/Synagogues'));
const EditSynagogue = lazy(() => import('../pages/Synagogues/EditSynagogue'));

const Donations = lazy(() => import('../pages/Donations'));
// const AddUser = lazy(() => import("../pages/Users/AddUser"));
// const EditUser = lazy(() => import("../pages/Users/EditUser"));

const Dashboard = lazy(() => import('../pages/Dashboard'));
const PushNotification = lazy(() => import('../pages/PushNotification'));

const Terms = lazy(() => import('../pages/Terms'));
const EditTerm = lazy(() => import('../pages/Terms/EditTerm'));

const Faq = lazy(() => import('../pages/Faq'));
const EditFaq = lazy(() => import('../pages/Faq/EditFaq'));

const Partners = lazy(() => import('../pages/Partners'));
const EditPartner = lazy(() => import('../pages/Partners/EditPartner'));

const Invoices = lazy(() => import('../pages/Invoices'));

const Banners = lazy(() => import('../pages/Banners'));
const EditBanner = lazy(() => import('../pages/Banners/EditBanner'));

const Subscriptions = lazy(() => import('../pages/Subscriptions'));
// const EditBanner = lazy(() => import("../pages/Banners/EditBanner"));

const AppRouter = () => {
  return (
    <Suspense fallback={null}>
      <Switch>
        {/* <Route path='/login' exact render={() => <Login />} />
        <Route
          path='/forgot-password'
          exact
          render={() => <ForgotPassword />}
        />
        <Route
          path='/reset-password/:token'
          exact
          render={() => <ResetPassword />}
        />
        ;
        <Route path='/sign-up' exact render={() => <SignUp />} />
        <Route path='/invite/:token' exact render={() => <Invite />} />; */}
        <Route path='/' exact render={() => <Dashboard />} />
        <Route
          path='/push-notifications'
          exact
          render={() => <PushNotification />}
        />
        {/* --- */}
        <Route path='/users' exact render={() => <Users />} />
        {/* <Route path='/users/add' exact render={() => <AddUser />} /> */}
        <Route path='/users/:userId' exact render={() => <EditUser />} />
        {/* --- */}
        <Route path='/admins' exact render={() => <Admins />} />
        {/* <Route path='/users/add' exact render={() => <AddUser />} /> */}
        <Route path='/admins/:adminId' exact render={() => <EditAdmin />} />
        {/* --- */}
        <Route path='/synagogues' exact render={() => <Synagogues />} />
        {/* <Route path='/users/add' exact render={() => <AddUser />} /> */}
        <Route
          path='/synagogues/:synagogueId'
          exact
          render={() => <EditSynagogue />}
        />
        <Route path='/donations' exact render={() => <Donations />} />
        <Route path='/invoices' exact render={() => <Invoices />} />
        {/* <Route path='/users/add' exact render={() => <AddUser />} /> */}
        {/* <Route path='/users/:userId' exact render={() => <EditUser />} /> */}
        {/* --- */}
        <Route path='/terms' exact render={() => <Terms />} />
        <Route path='/terms/add' exact render={() => <EditTerm />} />
        <Route path='/terms/:termId' exact render={() => <EditTerm />} />
        <Route path='/faqs' exact render={() => <Faq />} />
        <Route path='/faqs/add' exact render={() => <EditFaq />} />
        <Route path='/faqs/:termId' exact render={() => <EditFaq />} />
        <Route path='/partners' exact render={() => <Partners />} />
        <Route path='/partners/add' exact render={() => <EditPartner />} />
        <Route
          path='/partners/:partnerId'
          exact
          render={() => <EditPartner />}
        />
        <Route path='/banners' exact render={() => <Banners />} />
        <Route path='/banners/create' exact render={() => <EditBanner />} />
        <Route path='/banners/:bannerId' exact render={() => <EditBanner />} />
        <Route path='/subscriptions' exact render={() => <Subscriptions />} />
        <Route render={() => <Result status='404' title='404' />} />;
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
