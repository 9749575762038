import {Badge, Menu, Space} from 'antd';
import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {
  UserOutlined,
  BankOutlined,
  EnterOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  ContainerOutlined,
  PartitionOutlined,
  NotificationOutlined,
  ExceptionOutlined,
  PieChartOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import CommonStore from '../../store/CommonStore';
import {AdminRoles, Synagogue} from '../../types';
import {Link, useHistory, useLocation} from 'react-router-dom';
import api from '../../api/api';
import {Observer} from 'mobx-react';

type MenuType = {
  key: string;
  label: string | ReactNode;
  icon: ReactNode;
  items?: {key: string; label: string | ReactNode; icon: ReactNode}[];
};

const {SubMenu, Item} = Menu;

const NavigationBar: React.FC<{theme: 'dark' | 'light'}> = ({theme}) => {
  //
  const {t} = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const location = useLocation();

  //
  const [moderationQnt, setModerationQnt] = useState(0);

  //
  const menu: {[key: number]: MenuType[]} = {
    [AdminRoles.SUPERADMIN]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'admins',
        label: t('Admins'),
        icon: <UserOutlined />,
      },
      {
        key: 'synagogues',
        label: (
          <Space>
            {t('Synagogues')} <Badge count={moderationQnt} />
          </Space>
        ),
        icon: <BankOutlined />,
      },
      {
        key: 'users',
        label: t('Users'),
        icon: <TeamOutlined />,
      },
      {
        key: 'donations',
        label: t('Donations'),
        icon: <DollarCircleOutlined />,
      },
      {
        key: 'terms',
        label: t('Terms'),
        icon: <ContainerOutlined />,
      },
      {
        key: 'faqs',
        label: t('FAQ'),
        icon: <ContainerOutlined />,
      },
      {
        key: 'partners',
        label: t('Partners'),
        icon: <PartitionOutlined />,
      },
      {
        key: 'push-notifications',
        label: t('Push notification'),
        icon: <NotificationOutlined />,
      },
      {
        key: 'invoices',
        label: t('Invoices'),
        icon: <ExceptionOutlined />,
      },
      {
        key: 'banners',
        label: t('Advertising'),
        icon: <PieChartOutlined />,
      },
      {
        key: 'subscriptions',
        label: t('Subscriptions'),
        icon: <RedoOutlined />,
      },
    ],

    [AdminRoles.SYNAGOGUE]: [
      {
        key: '',
        label: t('Dasboard'),
        icon: <DashboardOutlined />,
      },
      {
        key: 'synagogues',
        label: t('Synagogues'),
        icon: <BankOutlined />,
        items:
          storeCommon.user?.synagogues !== undefined
            ? storeCommon.user?.synagogues.map((s: Synagogue) => {
                return {
                  key: `synagogues/${s.id}`,
                  label: s.name,
                  icon: <EnterOutlined />,
                };
              })
            : [],
      },
      {
        key: 'users',
        label: t('Users'),
        icon: <TeamOutlined />,
      },
      {
        key: 'donations',
        label: t('Donations'),
        icon: <DollarCircleOutlined />,
      },

      {
        key: 'push-notifications',
        label: t('Push notifications'),
        icon: <NotificationOutlined />,
      },
      {
        key: 'invoices',
        label: t('Invoices'),
        icon: <ExceptionOutlined />,
      },
    ],

    [AdminRoles.PARTNER]: [],
  };

  const activeRoute = location.pathname.split('/')[1] || '';

  //
  const openedKey = () => {
    let ret = '';
    if (storeCommon.user) {
      menu[storeCommon.user.role].forEach(item => {
        const search = item.items?.find(sub => {
          return sub.key.split('/')[0] === activeRoute;
        });
        if (search) {
          ret = item.key;
          return;
        }
      });
    }
    return ret;
  };

  //
  useEffect(() => {
    (async () => {
      if (storeCommon.user?.role !== AdminRoles.SUPERADMIN) return;

      const respSynagogues = await api(
        'synagogues?per-page=100&page=1&sort=id&filter[and][0][or][][synagogue.status]=10',
      );
      setModerationQnt(respSynagogues.total);
    })();
  }, [location.pathname]);

  return (
    <Observer>
      {() => (
        <Menu
          // onClick={this.handleClick}
          id={`menu-${storeCommon.user?.id}`}
          style={{width: 256}}
          mode='inline'
          theme={theme}
          defaultOpenKeys={[openedKey()]}
          defaultSelectedKeys={[activeRoute]}
          key={`menu-${activeRoute}`}>
          {storeCommon.user &&
            menu[storeCommon.user.role].map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {!item.items && (
                    <Item icon={item.icon} key={item.key}>
                      <Link to={`/${item.key}`}>{item.label}</Link>
                    </Item>
                  )}
                  {item.items && (
                    <SubMenu key={item.key} icon={item.icon} title={item.label}>
                      {item.items.map(sub => {
                        return (
                          <Item icon={sub.icon} key={sub.key}>
                            <Link to={`/${sub.key}`}>{sub.label}</Link>
                          </Item>
                        );
                      })}
                    </SubMenu>
                  )}
                </React.Fragment>
              );
            })}
        </Menu>
      )}
    </Observer>
  );
};

export default NavigationBar;
