const he = {
  translation: {
    // Login: "התחברות",

    Search: 'חיפוש',
    Reset: 'איפוס',
    Cancel: 'ביטול',
    Save: 'לשמור',
    Dasboard: 'לוח בקרה',
    Admins: 'מנהלים',
    Synagogues: 'מוסדות',
    Synagogue: 'מוסד',
    Users: 'משתמשים',
    Donations: 'תרומות',
    'Are you sure?': 'האם את/ה בטוח/ה?',
    Yes: 'כן',
    No: 'לא',
    'User Name': 'שם משתמש',
    email: 'דוא״ל',
    Phone: 'מס׳ טלפון',
    InvoiceId:'מספר קבלה',
    UserID :'מזהה משתמש',
    BuyerSocialId:'תעודת זהות',
    ID:'מזהה',
    Status: 'סטטוס',
    Deleted: 'מחוק',
    'Not Active': 'חסימת',
    'Pre Active': 'על תנאי',
    Active: 'פעיל',
    'Add admin': 'להוסיף מנהל',
    Installs: 'התקנות',
    'Use the sharing function': 'ביצוע שיתוף',
    'Amount of all donations': 'סה״כ תרומות',
    'Wait moderation': 'ממתין לאישור',
    'AVG donations amount by every hour':
      'סכום תרומות הממוצע  לפי שעה  (בתקופת פעילות של המוסד המערכת)',
    'Quantity donations by every hour':
      'כמות תרומות הממוצע  לפי שעה  (בתקופת פעילות של המוסד המערכת)',
    Amount: 'סכום',
    Verify: 'תאשר',
    'Verification token not found!': 'טוקן אמות לא נמצא!',
    'Checking your code...': 'קוד שלך בבדיקה...',
    Login: 'כניסה',
    'Required field': 'שדה חובה',
    Password: 'סיסמה',
    'Error!': 'שגיאה!',
    'Incorrect email or password!': 'שם משתמש או סיסמה אינם נכונים',
    'Whant to join us? Sign up now!': 'להצטרפות יש להירשם',
    'Sign Up': 'הרשמה',
    'Please confirm your password!': 'נא לאמת את הסיסמה',
    'The two passwords that you entered do not match!':
      'שתי סיסמאות שהקלדת אינן נכונות',
    'Confirm Password': 'אימות סיסמה',
    'Your request submited sucessfull!': 'בקשתך נשלחה בהצלחה !',
    'Check your email for further instructions.':
      'שלחנו בקשה לאימות כתובת המייל שהזנת, אנא בדוק את המייל ואמת בבקשה.',
    Edit: 'שינוי',
    'Add sunagogue': 'להוסיף חתימנ',
    'Synagogue name': 'שם של המוסד',
    'Charity Org Id': 'מס׳ ארגון',
    'Bank Account': 'שם הבנק',
    Address: 'כתובת',
    Logo: 'לוגו',
    'Drag or select file for upload!': 'בחר או גרור לכאן קובץ שברצונך להעלות',
    Files: 'קבצים',
    'All Donations': 'כל התרומות',
    'Total Donations by Synagogues': 'סה״כ תרומות לפי מוסד',
    Success: 'הצלחה',
    'Data saved sucessfully!': 'נתונים נשמרו הבהצלחה',
    Role: 'תפקיד',
    'Super admin': 'מנהל ראשי',
    Treasurer: 'גיזבר',
    User: 'משתמש',
    Date: 'תאריך',
    'Total Donations': 'סה״כ תרומות',
    'Treasurer Name': 'שם של הגיזבר',
    'Treasurer Phone': 'מס׳ טלפון של הגיזבר',
    'Date range donations': 'טווח תאריכים של התרומות',
    'Date registration': 'תאריך רישום',
    'Showing {{start}} - {{end}} of {{total}} records':
      'הוצג {{start}} - {{end}} מי {{total}} רשומות',
    Invited: 'בבדיקה',
    'Invited By': 'מפיצים',
    'Afilated Users': 'שיתופים',
    'Password must contain at least 8 characters, at least 1 number, one lowercase letter and at least 1 uppercase letter':
      'הסיסמה חייבת לכלול לפחות 8 תווים, סיפרה אחת, אות גדולה אחת ואות קטנה אחת',
    'Forgot pasword?': 'שכחתי סיסמה',
    'Please fill out your email. A verification email will be sent there.':
      'נא להוסיף דוא״ל לצורך שליחת מייל אימות',
    Send: 'שלח',
    // "Check your email for further instructions.":
    //   "נא לבדוק קבלת מייל עם הוראות להמשך התהליך",
    'Sorry, we are unable to reset password for the provided email address.':
      'לצערינו אי אפשר לאפס את הסיסמה לדוא״ל שנמסר',
    'There is no user with this email address.':
      'משתמש עם כתובת הדוא״ל הזה אינו קיים המערכת',
    'Reset password': 'איפוס סיסמה',
    'Your password changed sucessfully!': 'סיסמה שונתה בהצלחה',
    'Change password': 'בחר/י סיסמא חדשה',
    'Data generated sucessfully!': 'קובץ אקסל נוצר בהצלחה',
    'Internal server error!': 'אירוע שגיאה בשרת',
    'Export to Excel': 'הוצאה לאקסל',
    'Synagogue details': 'פרטי עמותה',
    'Synagogue title': 'שם העמותה',
    'Synagogue number': 'מספר עמותה',
    'Synagogue type': 'סוג עומתה\\פעלות',
    'Synagogue address': 'כתובת עמותה	',
    Country: 'מדינה',
    City: 'עיר',
    Street: 'רחוב',
    House: 'מספר בית',
    Acquiring: 'סליקה',
    'Acquiring Account': 'קיים חשבון סליקה',
    'Acquiring Provider': 'חברה',
    'Acquiring Id': 'מספר ספק',
    'Member signatory 1': 'מורשה חתימה ראשון',
    'Member signatory 2': 'מורשה חתימה שני',
    'Member name': 'שם מורשה חתימה',
    'Member ID': 'תז',
    'Member ID date': 'תאריך הנפקת ת.ז.',
    'Member gender': 'מין',
    'Date of birth': 'תאריך לידה',
    'Member phone': 'מספר טלפון',
    Contact: 'איש קשר',
    'Bank Branch': 'סניף',
    'Bank Account Number': 'מס׳ חשבון בנק',
    'Bank account': 'פרטי חשבון בנק',
    'Attachment of documents': 'צירוף מסמכים',
    'File Id': 'תז',
    'File Certificate': 'תעודת עמותה',
    'File Bank': 'אישור חשבון בנק',
    'File acquiring': 'הסטוריית סליקה אם קיים',
    'File Statement': 'חתימה על הצהרה לסליקה במאגד ובעלי שליטה',

    Terms: 'תנאי שימוש',
    'Terms and Сonditions': 'תנאי שימוש',
    FAQ: 'שאלות ותשובות',
    Faqs: 'שאלות ותשובות',

    'Credit cards Agreement': 'הסכם חברת אשראי',
    'PayMe Agreement': 'סכם פיימי',
    'PushCoins Agreement': 'הסכם פושקוינס',
    'Download for signing': 'הורדה וחתימה',
    'Push notification': 'הודעות פוש',
    Invoices: 'קבלות',
    'Resend invoice to client': 'שליחת קבלה חוזרת',
    Title: 'כותרת',
    Text: 'טקסט',
    'Send notification': 'שליחת הודעה',
    'Select synagogue': 'בחירת מוסד',
    'Create department': 'יצירת סניף למוסד',
    Partners: 'שותפים',

    'First Name': 'שם פרטי',
    'Last Name': 'שם משפחה',
    'Zip code': 'מיקוד',
    'Member percentage': 'כמות  מניות בחברה (באחוזים)',
    'File Id Second': 'ת.ז.  של מורשה חתימה  שני',

    'Synagogue regular commission': 'עמלה  רגילה',
    'Synagogue immediate commission': 'עמלה בתרומה מיידית',

    'Show invoice': 'הצג קבלה',

    'Monthly donations / transactions': 'תרומות / טרנזקציות  חודשיות',
    'Daily donations / transactions': 'תרומות / טרנזקציות יומיות',
    Transactions: 'טרנזקציות',
    'Export to one PDF': 'הוצאה ל PDF',
    'Select dates range to export Invoices': 'נא לבחור תווך  תאריכים',
    Start: 'התחל',
    Advertising: 'פרסום',
    'Add new': 'הוסף חדש',
    'Add new banner': 'הוסף באנר חדש',
    Image: 'תמונה',
    'Recommended size': 'גודל מומלץ',
    'From date': 'מתאריך',
    'To date': 'עד כה',
    'Last month Donations': 'תרומות בחודש האחרון',
  },
};
export default he;
